export const validatorPositive = value => {
    if (value >= 0) {
        return true
    }
    return false
}

export const validatorPassword = password => {
    /* eslint-disable no-useless-escape */
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
    /* eslint-enable no-useless-escape */
    const validPassword = regExp.test(password)
    return validPassword
}

export const validatorCreditCard = creditnum => {
    /* eslint-disable no-useless-escape */
    const cRegExp = /^(?:3[47][0-9]{13})$/
    /* eslint-enable no-useless-escape */
    const validCreditCard = cRegExp.test(creditnum)
    return validCreditCard
}

export const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
        return true
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
    /* eslint-enable no-useless-escape */
    return re.test(val)
}

export const validatorEMSO = (val) => {
    // https://sl.wikipedia.org/wiki/Enotna_mati%C4%8Dna_%C5%A1tevilka_ob%C4%8Dana -> formula from c#
    if (!val || val.length !== 13 || val.split().every(c => isNaN(Number(c)))) return false

    let emso_sum = 0
    for (let i = 7; i > 1; i--) {
        emso_sum += i * (Number(val.substring(7 - i, 7 - i + 1)) + Number(val.substring(13 - i, 13 - i + 1)))
    }

    const controlDigit = emso_sum % 11 === 0 ? 0 : 11 - (emso_sum % 11)
    return val[12] === String(controlDigit)
}

export const validatorTAX = (val) => {
    if (!val || val.length !== 8 || val.split().every(c => isNaN(Number(c)))) return

    let sum = 0
    for (let i = 0; i < 7; i++) {
        sum += Number(val[i] * (8 - i))
    }
    let control = 11 - (sum % 11)
    if (control === 10 || control === 11) control = 0
    return val[7] === String(control)
}
