import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import sl from 'vee-validate/dist/locale/sl.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorPassword,
  validatorEMSO,
  validatorTAX
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive
})

export const password = extend('password', {
  validate: validatorPassword
})

export const emso_rule = extend('emso_rule', {
  validate: validatorEMSO
})

export const tax_rule = extend('tax_rule', {
  validate: validatorTAX
})

export const max_text = extend('max_text', {
  validate: (value, param) => {
    if (value.length <= Number(param.number)) {
      return true
    }
    return false
  },
  params: ['number']
})

export const min_text = extend('min_text', {
  validate: (value, param) => {
    if (!value || !param) return false
    return value.length >= Number(param.number)
  },
  params: ['number']
})


const dictionary = {
  en: {
    messages:{
      positive: 'The {_field_} must be positive number',
      password: 'The {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
      emso_rule: 'The {_field_} is invalid',
      tax_rule: 'The {_field_} is invalid',
      max_text: (field, params) => `Length of the field ${field} must be less than ${params.number}`
    }
  },
  sl: {
    messages: {
      positive: 'Polje {_field_} mora biti pozitivno število',
      password: 'Polje {_field_} mora vsebovati vsaj eno veliko črko, eno malo črko, en poseben znak in eno številko',
      emso_rule: 'Polje {_field_} ni pravilno',
      tax_rule: 'Polje {_field_} ni pravilno',
      max_text: (field, params) => `Dolžina polja ${field} mora biti manj kot ${params.number}`
    }
  }
}
localize({
  en: {
    messages: en.messages
  },
  sl: {
    messages: sl.messages
  }
})
localize(dictionary)
